<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo">
      <template #companyName>Baxter</template>
      <template #date>2002 - 2016</template>
      <template #content>
        <ul class="dashed">
          <li>
            For 14 years (before their transition into Shire) we offered
            moderation for a variety of interactive booth activities that
            engaged with delegates and encouraged interaction and conversation
            around immunodeficiency diseases.
          </li>
          <li>
            The work started here would continue with Shire and then Takeda,
            spanning almost two decades.
          </li>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from '@/components/Portfolio/CompanyInfo.vue'
export default {
  components: { CompanyInfo },
  name: 'BaxterItem',
  data() {
    return {
      companyBrandColour: '#262967',
      companyLogo: require('@/assets/images/portfolio/baxter-logo.svg'),
      images: [
        {
          id: 1,
          responsiveImages: require('@/assets/images/portfolio/baxter/1.png'),
          position: { x: 50, y: 25 },
          pictureDetails: 'EUROPEAN CONGRESS FOR IMMUNOLOGY (2009) BERLIN',
        },
        {
          id: 0,
          responsiveImages: require('@/assets/images/portfolio/baxter/2.jpg'),
          position: { x: 50, y: 25 },
          pictureDetails:
            'EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2010) ISTANBUL',
        },
        {
          id: 2,
          responsiveImages: require('@/assets/images/portfolio/baxter/3.png'),
          position: { x: 50, y: 25 },
          pictureDetails: 'EUROPEAN CONGRESS FOR IMMUNOLOGY (2009) BERLIN',
        },
        {
          id: 3,
          responsiveImages: require('@/assets/images/portfolio/baxter/4.png'),
          position: { x: 50, y: 25 },
          pictureDetails: 'EUROPEAN CONGRESS FOR IMMUNOLOGY (2009) BERLIN',
        },
      ],
    }
  },
}
</script>
